<template>
  <div class="tootip">
    <hr>
    <div class="title">报考高手志愿填报内容说明</div>
    <div class="content">
      <div class="top"></div>
      <div class="title">
        重要提示
      </div>
      <ul class="liContent">
        <li>1、本系统依据历年院校录取数据数提供高考志愿填报智能模拟功能，不等同于实的网上填报志愿，建议考生在填报志愿时，结合系统的推荐以完善志愿表</li>
        <li>2、2024年招生计划已更新</li>
        <li>3、2010-2023年各院校录取数据，参考各省市出版的填报指南以及本专科院校的官网历年录取数据；</li>
        <li>4、您正在使用的是河南版，提供“普通类文理科本一批、本二批、专科批”3个批次的志愿智能模拟功能，您可以按系统的重点填报提示选择适合自己的批次模拟</li>
        <li>5、在正式填报时，以省教育考试院公布的最新招生计划为准；</li>
        <!-- <li>6、若推荐院校招生计划数出现新增专业，会提示“新增专业”且历三年录取数据展示默认采用该院校最低录取分数线。</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  
  
  
  
};
</script>

<style scoped lang='less'>
.tootip {
  width: 1150px;
  margin: 150px auto 40px;
  .title {
    margin-top: 30px;
    text-align: center;
  }
  .top {
    width: 20px;
    height: 20px;
    background: red;
    background: white;
    border: 1px solid #cdcdcd;
    border-bottom: 0;
    border-right: 0;
    position: absolute;
    transform: rotate(45deg);
    top: -11px;
    left: 560px;
  }
  .content {
    position: relative;
    border: 1px solid #d1d1d1;
    box-shadow: 2px 2px 1px #d1d1d1;
    margin-top: 30px;

    .title {
      color: red;
    }
  }

  .liContent {
    list-style: none;
    padding: 40px;
    line-height: 30px;
    color: #666666;
  }
}
</style>
